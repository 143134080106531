import { Component, NgZone, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { UserService, AuthServiceProvider } from '@ibitoll/toll-core';
import { ScriptService } from 'ngx-script-loader';
import jsonEn from '../assets/i18n/en.json';
import { StatusBar, Style } from '@capacitor/status-bar';
import { TextZoom } from '@capacitor/text-zoom';

import { Keyboard } from '@capacitor/keyboard';
import { PushNotificationService } from './services/push-notification.service';
import { ColorModeService } from './services/color-mode.service';
import { PushNotificationWebTestService } from './services/push-notification-web-test.service';

declare var zE: any;

@Component({
  selector: 'ibitoll-mdot',
  templateUrl: 'app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private platform: Platform,
    private translateService: TranslateService,
    private authService: AuthServiceProvider,
    public userService: UserService,
    private pushNotifService: PushNotificationService,
    private scriptService: ScriptService,
    private zone: NgZone,
    private router: Router,
    colorMode: ColorModeService,
    private messagingService: PushNotificationWebTestService
  ) {
    this.initializeApp();

    const jsonEnData = jsonEn as any;
    this.translateService.setTranslation('en', jsonEnData, true);

    this.translateService.setDefaultLang('en');
    this.translateService.use('en');
  }

  ngOnInit(): void {
    document.body.style.backgroundColor = '#ffc107';

    this.pushNotifService.initialize();

    setTimeout(() => {
      // if (this.platform.is('ios')) {
      //   zE('webWidget:on', 'open', function () {
      //     setTimeout(() => {
      //       (document.querySelector('#webWidget') as any).style.top = '48px';
      //       (document.querySelector('#webWidget') as any).style.height = '92%';
      //     }, 200);
      //   });
      // }

      //zE?.show();
    }, 200);
  }

  initializeApp() {
    this.platform
      .ready()
      .then(() => {
        if (this.platform.is('ios')) {
          TextZoom.getPreferred()
            .then((data) => {
              this.setTextZoom(data.value);
            })
            .catch(() => {});
        }
        try {
          setTimeout(() => {
            Keyboard.addListener('keyboardWillShow', (info) => {
              zE?.hide();
            });

            Keyboard.addListener('keyboardWillHide', () => {
              //zE?.show();
            });
          }, 800);
        } catch {
          // ignore the error
          // Error needs to handled
        }

        setTimeout(() => {
          //zE?.show();
        }, 1000);
      })
      .catch(() => { });

      if (!this.platform.is('cordova')) {
        this.messagingService.requestPermission();
        this.messagingService.receiveNotification();
      }
  }

  logOut() {
    this.authService.logout();
    this.router.navigate(['login']);
  }

  setTextZoom(value: number): void {
    void TextZoom.set({ value });
  }

  getUsername(): string {
    const currentUser = this.userService.getCurrentUser();
    return currentUser ? currentUser.UserName : '';
  }
}
