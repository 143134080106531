import { Router } from '@angular/router';
import {
  UserService,
  UserDeviceQuery,
  LogService,
  LocalstorageService,
  CURRENT_USER,
  AlertService,
} from '@ibitoll/toll-core';
import { Injectable, NgZone } from '@angular/core';
import { Platform } from '@ionic/angular';

import {
  ActionPerformed,
  PushNotificationSchema,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { Device } from '@capacitor/device';
import { LocalNotifications } from '@capacitor/local-notifications';
import { ClosureDataService } from '../pages/bridge-management/brige-closures/closure.service';
import { FCM } from '@capacitor-community/fcm';
import { AlertMobileService } from './alert-mobile.service';
import { Observable } from 'rxjs';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';


@Injectable({
  providedIn: 'root',
})
export class PushNotificationService {
  constructor(
    private platform: Platform,
    public logService: LogService,
    private alertMobileService: AlertMobileService,
    private closureService: ClosureDataService,
    private router: Router,
    private zone: NgZone,
    private localstorageService: LocalstorageService,
    private userService: UserService,
    private androidPermissions: AndroidPermissions
  ) { }

  async initialize() {

    await PushNotifications.removeAllListeners();

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      (token: Token) => {
        //alert('Push registration success, token: ' + token.value);
        console.log(token.value);

        FCM.getToken()
          .then((fcmToken) => {
            //this.registerBridgeUserDevice(fcmToken.token);
            this.initializeFCM(fcmToken.token);
          })
          .catch((err) => {
          });

        PushNotifications.createChannel(
          {
            id: "Channel ID",
            name: "Channel",
            importance: 5,
          }
        );
        
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        //alert('Error on registration: ' + JSON.stringify(error));
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        //alert('Push received: ' + JSON.stringify(notification));
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener(
      'pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        this.zone.run(() => {
          console.log(`Push action performed: ${JSON.stringify(notification)}`);
          this.logService.logMessage(
            'Push action performed: ' + JSON.stringify(notification)
          );
          
          if (notification.notification?.data?.alert_type) {
            if (notification.notification?.data?.alert_type.includes('statement')) {
              this.router.navigate(['/home/statement']);
            }

            if (notification.notification?.data?.alert_type.includes('payment')) {
              this.router.navigate(['/home/payment']);
            }

            if (notification.notification?.data?.alert_type.includes('bridge-closures')) {
              if (notification.notification?.data?.bridge_id) {
                setTimeout(() => {
                  this.router.navigate([
                    `/bridge/closures/${notification.notification?.data?.bridge_id}`,
                  ]);
                }, 800);
              } else {
                setTimeout(() => {
                  this.router.navigate(['/bridge/closures/']);
                }, 800);
              }
            }

            if (notification.notification?.data?.alert_type.includes('bridge-alerts')) {
              setTimeout(() => {
                this.router.navigate(['/bridge/alert/macDonald']);
              }, 800);
            }
          }
        });
      }
    );

    if (this.platform.is('ios')) {
      PushNotifications.requestPermissions().then(result => {
        if (result.receive === 'granted') {
          // Register with Apple / Google to receive push via APNS/FCM
          PushNotifications.register();
        } else {
          // Show some error
        }
      });
    }

    if (this.platform.is('android')) {
      this.androidPermissions.checkPermission(this.androidPermissions.PERMISSION.POST_NOTIFICATIONS).then(
        (res) => { 
          if (!res.hasPermission){
            this.androidPermissions.requestPermissions([this.androidPermissions.PERMISSION.POST_NOTIFICATIONS]).then(result => {
              if (result.hasPermission) {
                // Register with Google to receive push via APNS/FCM
                PushNotifications.register();
              } else {
                // Show some error
              }
            })
          }
          else{
            PushNotifications.register();
          }
        }
        // (err) => {
        //   this.androidPermissions.requestPermission(this.androidPermissions.PERMISSION.POST_NOTIFICATIONS)
        // }
      );
    }
    
    if (!this.platform.is('cordova')) {
      this.alertMobileService.presentAlert(
        "Warning",
        this.platform.platforms().join(),
        'Push notifications not initialized. Cordova is not available - Run in physical device',
      );
      return;
    } else {
      // now you can subscribe to a specific topic
      // this.fcmInit();
    }

    /*
    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration', (token: Token) => {
      console.log('CURRENT_USER', this.localstorageService.check(CURRENT_USER));
      if (this.localstorageService.check(CURRENT_USER)) {
        this.registerUserDevice(token.value).then(() => {
          this.logService.logMessage(
            `Device Registered for Push Notification: ${token.value}`
          );
        });
      } else {
        this.registerBridgeUserDevice(token.value).then(() => {
          this.logService.logMessage(
            `Device Registered for Push Notification: ${token.value}`
          );
        });
      }
    });

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError', (error: any) => {

      this.alertMobileService.presentAlert(
        "Error registrationError",
        "",
        "",
      );

      this.logService.logMessage(
        'Error on registration: ' + JSON.stringify(error)
      );
    });
    */

    /*
    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener(
      'pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        this.zone.run(() => {

          this.alertMobileService.presentAlert(
            "Received notification",
            "",
            "",
          );

          this.logService.logMessage(
            'Push received initializePushNotificationListener: ' +
            JSON.stringify(notification)
          );

          console.log(
            'Push received initializePushNotificationListener: ' +
            JSON.stringify(notification)
          );

          // tslint:disable-next-line: no-console
          if (this.platform.is('android')) {
            LocalNotifications.schedule({
              notifications: [
                {
                  title: notification.title,
                  body: notification.body,
                  id: 1,
                  schedule: { at: new Date(Date.now() + 1000 * 1) },
                  attachments: null,
                  actionTypeId: '',
                  extra: null,
                },
              ],
            }).catch((error) => {
              // tslint:disable-next-line: no-console
              console.debug(
                'initializePushNotificationListener notifications denied',
                error
              );
            });
          }
        });
      }
    );
    */
  }

  private initializeFCM(token: string) {
    /*
    FCM.subscribeTo({ topic: 'test-closures' })
      .then((r) => {
      })
      .catch((err) => {
      });
    */
    // // Unsubscribe from a specific topic
    // FCM.unsubscribeFrom({ topic: 'closures' })
    //   .then(() => {
    //     this.router.navigate(['/bridge/closures']);
    //     this.checkunReadClouser();
    //   })
    //   .catch((err) => console.log(err));

    // Get FCM token instead the APN one returned by Capacitor
    this.registerBridgeUserDevice(token);

    // Enable the auto initialization of the library
    FCM.setAutoInit({ enabled: true }).then(() => {
      //this.logService.logMessage(`Auto init enabled`);
    });

    // Check the auto initialization status
    FCM.isAutoInitEnabled().then((r) => {
      //this.logService.logMessage(
      //  'Auto init is ' + (r.enabled ? 'enabled' : 'disabled')
      //);
    });
  }

  public subscribeToTopic(topic: string) : Promise<{message: string}> {
    return FCM.subscribeTo({ topic: topic });
  }

  public unsubscribeFromTopic(topic: string) : Promise<{message: string}> {
    return FCM.unsubscribeFrom({ topic: topic });
  }

  //////////////////////////////////////////////////////////////////////////////////////

  private async registerUserDevice(token: string) {
    const currentUser = this.userService.getCurrentUser();

    Device.getInfo().then((info) => {
      Device.getLanguageCode().then((langCode) => {
        const userDeviceQuery = new UserDeviceQuery();
        userDeviceQuery.AccountId = currentUser?.AccountId?.toString();
        userDeviceQuery.DeviceToken = token;
        userDeviceQuery.DeviceType = info.operatingSystem;
        userDeviceQuery.DeviceLanguageCode = langCode.value;
        userDeviceQuery.NotificationType = 'AD';

        this.userService.RegisterUserDevice(userDeviceQuery).subscribe(
          () => { },
          (error) => {
            this.logService.logMessage(
              'RegisterUserDevice process failed at registerUserDevice' +
              JSON.stringify(userDeviceQuery) +
              'Error:' +
              JSON.stringify(error)
            );
          }
        );
      });
    });
  }

  private async registerBridgeUserDevice(token: string) {
    Device.getInfo().then((info) => {
      Device.getLanguageCode().then((langCode) => {
        const userDeviceQuery = new UserDeviceQuery();
        userDeviceQuery.AccountId = '0';
        userDeviceQuery.DeviceToken = token;
        userDeviceQuery.DeviceType = info.operatingSystem;
        userDeviceQuery.DeviceLanguageCode = langCode.value;
        userDeviceQuery.NotificationType = 'BD';

        this.userService.RegisterBridgeDevice(userDeviceQuery).subscribe(
          () => { },
          (error) => {
            this.logService.logMessage(
              'RegisterBridgeDevice process failed at registerUserDevice' +
              JSON.stringify(userDeviceQuery) +
              'Error:' +
              JSON.stringify(error)
            );
          }
        );
      });
    });
  }
}
