// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  convergePayScript: 'https://api.demo.convergepay.com/hosted-payments/Checkout.js',
  chaseWrapperUrl: 'https://ibihhb.club/ChaseWrapper?uid=',
  //inAppChaseWrapperUrl: 'https://ibihhb.club/payment/chase-wrapper',
  inAppChaseWrapperNewCardUrl: 'https://ibihhb.club/payment/chase-wrapper-new-card',
  signupUrl: 'https://ibihhb.club/signup',
  // **************************************************
  // * Config is DEPRECIATED here since July 2024     *
  // * it is loaded from accets/environment.json      *
  // * See: loadJsonConfig() in ConfigurationService  *
  // **************************************************
  config: {
    serverUrl: '/',
    Name: 'hhb',
    ClientName: 'Halifax Harbour Bridges',
    ClientId: '123',
    LogoImageUrl: 'assets/images/logo_bwb.png',
    BackgroundImageUrl: 'assets/images/background_bwb.jpg',
    HasLanguage: false,
    DefaultLanguage: 'en',
    DualCurrency: false,
    DefaultCurrency: '$',
    DefaultCurrencyCode: 'en-US',
    RequestTransponder: true,
    PaymentProvider: 'elavon',
    CSREmail: 'macpass@hdbc.ns.ca',
    webRequestOptions: [
      {
        Name: 'ACCOUNT_NUMBER_OPTION',
        Value: 'accountNumber',
      },
      {
        Name: 'TRANSPONDER_NUMBER_OPTION',
        Value: 'transponderNumber',
      },
    ],
    DefaultLang: 'en',
    UseDefaultLang: 'en',
    pages: [
      {
        title: 'HOME',
        icon: 'home',
        imageSrc: '',
        component: 'BalancePage',
      },
      {
        title: 'PAYMENT',
        icon: 'card',
        imageSrc: '',
        component: 'ConvergepaymentPage',
      },
      {
        title: 'TRANSPONDERS',
        icon: 'ios-radio',
        imageSrc: '',
        component: 'TransponderPage',
      },
      {
        title: 'ACCOUNT',
        icon: 'contact',
        imageSrc: '',
        component: 'AccountPage',
      },
    ],
    customeComponents: [],
    GoogleAnalyticsKey: '',
    GoogleRecaptch: '6Leg3EAiAAAAAEnGvHfFwiAVIsxSWQhYwi4U0b5y',
    SessionTimeOut: 600,
    ProductionCdnScripts: [],
    Type: 'mobile',
    Version: '2.0.0',
    AndroidVersion: '6.7',
    iOSVersion: '1.40.3',
    WebNotificationSubscriptionURL: "http://hhb-stag-alb-public-1791246923.us-east-1.elb.amazonaws.com/api/TopicSubscriber/SubscribeToTopic",
    WebNotificationSubscriptionAPIKey: "DWKx4rLgGxFoKKmf6ZD4DxzJFN31t9r47AtQtQ3fYSugXXJU9QQXU9yaNTirnKqw",
    firebaseConfig: {
      apiKey: "AIzaSyCKtGFJY3dTBepVSyAvx2Y0iinAVUsIMYs",
      authDomain: "dev-hhb-bridge-management.firebaseapp.com",
      projectId: "dev-hhb-bridge-management",
      storageBucket: "dev-hhb-bridge-management.appspot.com",
      messagingSenderId: "583329201645",
      appId: "1:583329201645:web:988fdaa5e7a935c0b67be4",
      measurementId: "G-DYJB0BQ3KH"
    }
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
