import { Injectable } from '@angular/core';
import { ConfigurationService } from './configuration.service';
import { Platform } from '@ionic/angular';
import { AlertMobileService } from './alertmobile.service';

@Injectable({
  providedIn: 'root',
})
export class MobileVersionService {
  

  constructor(
    private config: ConfigurationService,
    private platform: Platform,
    private alertMobileService: AlertMobileService,

  ) {
    
  }

  checkVersion(httpResponse){
    if (this.config.config?.Type === 'mobile') {
            
      if (this.platform.is('ios')) {
        let requiredIOSVersion = httpResponse.headers.get('required-ios-version')
        if (requiredIOSVersion){
          const currentIOSVersion = this.config.config.iOSVersion

          if (this.isCurrentVersionSmallerThanRequiredVersion(currentIOSVersion, requiredIOSVersion)){
            this.alertMobileService.showUpdateVersion('UPDATE_VERSION_BODY', 'UPDATE_VERSION_TITLE');
              throw new Error('App update required');
          }
        }
      } 
      else{
        let requiredAndroidVersion = httpResponse.headers.get('required-android-version')
        if (requiredAndroidVersion){
          const currentAndroidVersion = this.config.config.AndroidVersion
          if (this.isCurrentVersionSmallerThanRequiredVersion(currentAndroidVersion, requiredAndroidVersion)){
            this.alertMobileService.showUpdateVersion('UPDATE_VERSION_BODY', 'UPDATE_VERSION_TITLE');
              throw new Error('App update required');
          }
        }
      }
    }


  }


  isCurrentVersionSmallerThanRequiredVersion(currentVersion, requiredVersion){
    const currentVersionParts = currentVersion.split('.');
    const requiredVersionParts = requiredVersion.split('.');

    for (let i = 0; i < currentVersionParts.length; i++) {
      const currPart = parseInt(currentVersionParts[i])
      const reqPart = parseInt(requiredVersionParts[i])

      if (currPart < reqPart){
        return true
      }
      else if (currPart > reqPart){
        return false
      }
    }

    return false
    
  }

}