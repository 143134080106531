import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, of, ReplaySubject, Subject } from 'rxjs';
import { Statement } from '../models/Statement';
import { StatementsInfoRequest } from '../models/StatementInfo';
import { StatementInfo } from '../models/StatementInfo';
import { DocumentContentRequest } from '../models/StatementInfo';
import { DocumentType } from '../models/StatementInfo';
import { Account } from './../models/account';
import { ActivityQuery } from './../models/activityQuery';
import { ActivityPdfQuery } from './../models/transactionPdfRecord';

import { Employee } from './../models/employee';
import { Invoice } from './../models/invoice';
import { ACTIVE_ACCOUNT } from './../models/keyConstand';
import { StatementInfoQuery } from './../models/StatementInfoQuery';
import { StatementQuery } from './../models/statementQuey';
import { SuspendTagQuery, SuspendTagQueryList } from './../models/suspendTagQuery';
import { TransactionRecord } from './../models/transactionRecord';
import { TransactionResponse } from './../models/transactionResponse';
import { Transponder } from './../models/transponder';
import { TransponderRequest } from './../models/transponderRequest';
import { User } from './../models/user';
import { ProxyService } from './proxy.service';

import {
  map,
  publishReplay,
  refCount,
  share,
  switchMap,
  tap,
} from 'rxjs/operators';
import { SubSink } from 'subsink';
import { AccountInfo } from '../models/accountInfo';
import { CreditCard } from '../models/creditCard';
import { TransactionPdfRecord } from '../models/transactionPdfRecord';
import { EInvoiceStatusUpdateQuery } from '../models/EInvoiceStatusUpdateQuery';
import { CURRENT_USER } from '../models/keyConstand';
import { StatementInfoRecord } from '../models/statementInfoRecord';
import { DatabaseMapService } from './databasemap.service';
import { LocalstorageService } from './localstorage.service';
import { ReCaptchaService } from './re-captcha.service';
import { LogService} from './log.service';
import { AccountCommConfigWeb } from '../models/accountCommConfigWeb';
import { AccountCommConfigQuery } from '../models/accountCommConfigQuery';
import { WebUserCardInfo } from '../models/webUserCardInfo';
import { Cacheable } from 'ts-cacheable';
import { AccountUpdateRequest } from '../models/accountUpdateRequest';
import { ConfigurationService } from './configuration.service';
import { TransponderExport } from '../models/transponderExport';

@Injectable({
  providedIn: 'root',
})
export class AccountService implements OnDestroy {
  public currentAccount: Account;

  public signUpAccount: Account;

  public selectedAccount: Account = new Account();

  public activeAccount = new ReplaySubject<Account>(1);

  private loadTime: Date;

  private expiryMinutes = 15; // Data expiry period. After this time, code will reload account data from DB.

  private endpointUrl: string;

  private accountEndpoint = 'api/Account';

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  private headers2 = new HttpHeaders({
    'Content-Type': 'application/json',
    ResponseType: 'arraybuffer' as 'json',
  });

  private cachedRequestedVehicleInfo: Observable<Transponder[]>;

  public accountObservable: Observable<Account>;

  private accountTranspondersObservableCache: {
    [key: number]: Observable<Transponder[]>;
  } = {};

  private accountTranspondersCache: { [key: number]: Transponder[] } = {};

  private subs = new SubSink();

  constructor(
    private http: HttpClient,
    private proxy: ProxyService,
    private databaseMapService: DatabaseMapService,
    private reCaptchaService: ReCaptchaService,
    private localstorageService: LocalstorageService,
    private logService: LogService,
    private configService: ConfigurationService,
    private translate: TranslateService
  ) {
    this.subs.add(
      this.proxy.getEndpoint().subscribe((url) => {
        this.endpointUrl = url;
      })
    );
  }

  setLoadTime(time: Date) {
    this.loadTime = time;
  }

  clearActiveAccount() {
    this.activeAccount.complete();
    this.accountTranspondersCache = {};
    this.accountTranspondersObservableCache = {};
    this.activeAccount = new ReplaySubject<Account>(1);
  }

  resetAllAccountServiceCache() {
    this.resetCachedRequestedVehicleInfo();
    this.resetCachedAccountObservable();
    this.activeAccount.complete();
    this.activeAccount = new ReplaySubject<Account>(1);
  }

  resetCachedAccountObservable() {
    this.accountObservable = null;
  }

  resetCachedRequestedVehicleInfo() {
    this.cachedRequestedVehicleInfo = null;
  }

  loadActiveAccount(accountId: number): Observable<Account> {
    return this.getRefreshAccount(accountId).pipe(
      tap((x) => {
        this.updateLanguage(x.Language);
        this.setActiveAccount(x);
      })
    );
  }

  setActiveAccount(value: Account) {
    value.LoadTime = this.loadTime;
    this.setLocalSelectedAccountID(value);
    this.selectedAccount = value;
    this.activeAccount.next(value);
  }

  setLocalSelectedAccountID(value: Account) {
    this.localstorageService.setItem(
      ACTIVE_ACCOUNT,
      value.AccountID.toString()
    );
  }

  getLocalSelectedAccountID(): number {
    return Number(this.localstorageService.getItem(ACTIVE_ACCOUNT));
  }

  getActiveAccount(): Observable<Account> {
    return this.activeAccount;
  }

  getActiveAccountInfo(): Account {
    this.checkIfInfoExpired();
    return this.selectedAccount;
  }

  checkIfInfoExpired() {
    if (this.selectedAccount && this.selectedAccount.LoadTime) {
      const expiry = new Date(
        this.selectedAccount.LoadTime.getTime() + this.expiryMinutes * 60000
      );
      if (expiry < new Date()) {
        this.getRefreshAccount(this.selectedAccount.AccountID).subscribe(
          (x) => {
            this.setLoadTime(new Date());
            this.setActiveAccount(x);
          }
        );
      }
    }
  }

  checkAccountStatus(hideStatus: boolean): boolean {
    if (this.selectedAccount) {
      if (this.selectedAccount.AccountStatus === 5 && hideStatus) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  getAccountStatement(statementQuery: StatementQuery): Observable<Statement[]> {
    return this.http
      .post<Statement[]>(
        this.endpointUrl + this.accountEndpoint + '/DownloadAccountStatement',
        JSON.stringify(statementQuery),
        { headers: this.headers2 }
      )
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  /**
   * Download a statement for a specific account
   * @param {number} accountId - The ID of the account you want to download the statement for.
   * @param {string} statementDate - The date of the statement you want to download.
   * @returns The `downloadStatement` method returns an `Observable` of type `Blob`.
   */
  downloadStatement(
    accountId: number,
    statementDate: string
  ): Observable<Blob> {
    const statementQuery: StatementQuery = new StatementQuery();
    statementQuery.AccountId = accountId;
    statementQuery.Statement = new Statement();
    statementQuery.Statement.BillingDate = statementDate;
    const url =
      this.endpointUrl + this.accountEndpoint + '/DownloadAccountStatement';

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });

    return this.http.post<Blob>(url, statementQuery, {
      headers: headers,
      responseType: 'blob' as 'json',
    });
  }

  downloadActivityPdf(
    pdfData: ActivityPdfQuery
  ): Observable<Blob> {
    // const statementQuery: StatementQuery = new StatementQuery();
    // statementQuery.AccountId = accountId;
    // statementQuery.Statement = new Statement();
    // statementQuery.Statement.BillingDate = statementDate;
    const url =
      this.endpointUrl + this.accountEndpoint + '/DownloadActivityPdf';

    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Accept: 'application/json',
    });
    return this.http.post<Blob>(url, pdfData, {
      headers: headers,
      responseType: 'blob' as 'json',
    });
  }

  
  // Only for EPT
  getStatementToken(accountId: number, statementDate: string) {
    const statementQuery: StatementQuery = new StatementQuery();
    statementQuery.AccountId = accountId;
    statementQuery.Statement = new Statement();
    statementQuery.Statement.BillingDate = statementDate;
    const url = this.endpointUrl + this.accountEndpoint + '/statementToken';

    return this.http.post(url, statementQuery);
  }

  getStatementInfoRecords(
    accountId: number
  ): Observable<StatementInfoRecord[]> {
    const params = new HttpParams().set('accountId', accountId.toString());
    return this.http.get<StatementInfoRecord[]>(
      this.endpointUrl + this.accountEndpoint + '/GetStatementInfoRecords',
      { headers: this.headers, params }
    );
  }

  /**
   * Get the statement information records for a given statement period
   * @param {StatementInfoQuery} statementQuery - The query to be executed.
   * @returns The `GetStatementInfoRecordsForStatementPeriod` method returns a `Blob` object.
   */
  getStatementInfoRecordsForStatementPeriod(
    statementQuery: StatementInfoQuery
  ): Observable<Blob> {
    const url =
      this.endpointUrl +
      this.accountEndpoint +
      '/GetStatementInfoRecordsForStatementPeriod';
    return this.http
      .post(url, statementQuery, {
        headers: this.headers,
        responseType: 'blob',
      })
      .pipe(
        map((r: Blob) => {
          return r;
        })
      );
  }

  getAccountBillingDateInfo(accountId: number): Observable<Statement[]> {
    const params = new HttpParams().set('accountId', accountId.toString());
    return this.http.get<Statement[]>(
      this.endpointUrl + this.accountEndpoint + '/GetAccountBillingDateInfo',
      { headers: this.headers, params }
    );
  }

  getAccountCommConfig(
    accountId: number,
    accountProfileID: number
  ): Observable<AccountCommConfigWeb[]> {
    const request: AccountCommConfigQuery = new AccountCommConfigQuery();
    request.AccountId = accountId;
    request.AccountProfileID = accountProfileID;

    return this.http.post<AccountCommConfigWeb[]>(
      this.endpointUrl + this.accountEndpoint + '/GetAccountCommConfig',
      request,
      { headers: this.headers }
    );
  }

  UpdateAccountCommConfig(
    accountCommConfig: AccountCommConfigQuery
  ): Observable<boolean> {
    return this.http.post<boolean>(
      this.endpointUrl + this.accountEndpoint + '/UpdateAccountCommConfig',
      accountCommConfig,
      { headers: this.headers }
    );
  }

  downloadDocument(
    statement: StatementInfo,
    docType: DocumentType
  ): Observable<Blob> {
    const request: DocumentContentRequest = new DocumentContentRequest();
    request.DocumentType = docType;
    request.DocumentInfo = statement;

    return this.http
      .post(
        this.endpointUrl + this.accountEndpoint + '/GetDocumentContent',
        request,
        { headers: this.headers, responseType: 'blob' }
      )
      .pipe(
        map((r: Blob) => {
          return r;
        })
      );
  }

  /**
   * Get the account statements for a given account and year
   * @param {User} user - User
   * @param {string} accountId - The account number of the account you want to get statements for.
   * @param {string} eInvoiceStatus - The status of the eInvoices.
   * @param {number} year - The year of the statement.
   * @returns The response is an array of StatementInfo objects.
   */
  getAccountStatemetsInfo(
    user: User,
    accountId: string,
    eInvoiceStatus: string,
    year: number
  ): Observable<StatementInfo[]> {
    const request: StatementsInfoRequest = new StatementsInfoRequest();

    request.AccountId = accountId;
    request.Year = year;
    request.EInvoiceStatus = eInvoiceStatus;
    request.User = user;

    return this.http.post<StatementInfo[]>(
      this.endpointUrl + this.accountEndpoint + '/GetAccountStatements',
      request,
      { headers: this.headers }
    );
  }

  /**
   * It returns an Observable of Employee[]
   * @param {number} accountId - The accountId of the account you want to get employees for.
   * @returns An Observable of Employee[]
   */
  getEmployees(accountId: number): Observable<Employee[]> {
    const params = new HttpParams().set('accountId', accountId.toString());
    return this.http
      .post<Employee[]>(
        this.endpointUrl + this.accountEndpoint + `/GetEmployees/${accountId}`,
        { headers: this.headers, params }
      )
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  /**
   * *Set the amount to replenish the card's balance.*
   * @param {CreditCard} creditCard - CreditCard - The credit card object that you want to update.
   * @param {number} balanceReplenishAmount - The amount to replenish the card's balance.
   * @returns The observable is returning a boolean value.
   */
  setCardPreAuthorizationAmount(
    creditCard: CreditCard,
    balanceReplenishAmount: number
  ): Observable<boolean> {
    const preAuthCard: CreditCard = JSON.parse(JSON.stringify(creditCard));
    preAuthCard.BalanceReplenishAmount = balanceReplenishAmount;
    return this.updatePaymentDetails(preAuthCard);
  }

  enableCardPreAuthorization(
    creditCard: CreditCard,
    enabled: boolean
  ): Observable<boolean> {
    const preAuthCard: CreditCard = JSON.parse(JSON.stringify(creditCard));
    preAuthCard.PreauthStatus = enabled
      ? this.databaseMapService.ePreAuthStatus.active
      : this.databaseMapService.ePreAuthStatus.suspended;
    return this.updatePaymentDetails(preAuthCard);
  }

  /**
   * * Remove a credit card from an account
   * @param {CreditCard} creditCard - CreditCard
   * @returns The observable is returning a boolean value.
   */
  removeCreditCardFromAccount(creditCard: CreditCard): Observable<boolean> {
    const deleteCard = new CreditCard();
    deleteCard.UserId = creditCard.UserId;
    deleteCard.UserLogonId = creditCard.UserLogonId;
    deleteCard.AccountId = creditCard.AccountId;
    deleteCard.PaymentType = null;
    deleteCard.PaymentCardType = null;
    deleteCard.PaymentNum = null;
    deleteCard.PaymentLastName = null;
    deleteCard.PaymentCardExpiryYear = null;
    deleteCard.PaymentCardExpiryMonth = null;
    deleteCard.BalanceReplenishAmount = null;
    deleteCard.CardBankTokenId = null;
    deleteCard.PreauthStatus = this.databaseMapService.ePreAuthStatus.disabled; // to remove preauth payment

    return this.updatePaymentDetails(deleteCard).pipe(
      map(() => {
        return true;
      })
    );
  }

  getCreditCard(
    AccountId: number,
    forceRefresh: boolean = false
  ): Observable<CreditCard> {
    return this.getAccount(AccountId, forceRefresh).pipe(
      map((account) => {
        const creditCard = new CreditCard();
        creditCard.UserId = this.getCurrentUser().UserId;
        creditCard.UserLogonId = this.getCurrentUser().UserName;
        creditCard.AccountId = account.AccountID;
        creditCard.PaymentType = account.PaymentType;
        creditCard.PaymentCardType = account.PaymentCardType;
        creditCard.PaymentNum =
          account.PaymentNumber != null ? account.PaymentNumber.toString() : '';
        creditCard.PaymentLastName = account.PreAuthCardHolder;
        creditCard.PaymentCardExpiryYear = account.PreAuthCardExpiryYear;
        creditCard.PaymentCardExpiryMonth = account.PreAuthCardExpiryMonth;
        creditCard.BalanceReplenishAmount = account.TopUpAmount; // ??
        creditCard.BalanceReplenishThreshold = account.LowBalanceAmount;
        creditCard.CardBankTokenId = account.BankCardTokenId;
        creditCard.PreauthStatus = account.PreAuthStatus;
        return creditCard;
      })
    );
  }

  updatePaymentDetails(cardUpdate: CreditCard): Observable<boolean> {
    return this.reCaptchaService.refresh('UpdatePaymentDetails').pipe(
      switchMap(() => {
        return this.http.post<boolean>(
          this.endpointUrl + this.accountEndpoint + '/UpdatePaymentDetails',
          JSON.stringify(cardUpdate),
          { headers: this.headers }
        );
      })
    );
  }

  updatePaymentDetailsWithChase(cardUpdate: CreditCard): Observable<boolean> {
    return this.reCaptchaService.refresh('UpdatePaymentDetailsWithChase').pipe(
      switchMap(() => {
        return this.http.post<boolean>(
          this.endpointUrl +
            this.accountEndpoint +
            '/UpdatePaymentDetailsWithChase',
          JSON.stringify(cardUpdate),
          { headers: this.headers }
        );
      })
    );
  }

  /**
   * It updates the account information for the account.
   * @param {Account} accountUpdate - Account
   * @returns The updated account information.
   */
  updateAccountInformation(accountUpdate: Account): Observable<Account> {
    var a = AccountUpdateRequest.fromAccount(accountUpdate);
    const concatStr = `${a.AccountID}${a.Email}${a.AltEmail}${a.PrimaryPhone}${a.MobilePhone}`
      +`${a.BillingAddress}${a.BillingCity}${a.BillingCountryID}${a.BillingProvinceID}${a.BillingPostalCode}`
      +`${a.MailingAddress}${a.MailingCity}${a.MailingCountryID}${a.MailingProvinceID}${a.MailingPostalCode}`;
    //Object.keys(acc)      .map(key=> acc[key])      .join('|');
    a.CheckHash = this.logService.getShortHash(concatStr);
    console.log('updateAccountInformation1:' + concatStr + '; hash: ' + a.CheckHash);
    return this.reCaptchaService.refresh('UpdateAccountInformation').pipe(
      switchMap(() => {
        return this.http.post<Account>(
          this.endpointUrl + this.accountEndpoint + '/UpdateAccountInformation',
          JSON.stringify(a),
          { headers: this.headers }
        );
      })
    );
  }

  updateSuspendTag(suspendTagQuery: SuspendTagQuery): Observable<boolean> {
    suspendTagQuery.TagList = "testingsuspend";
    return this.http.post<boolean>(
      this.endpointUrl + this.accountEndpoint + '/UpdateSuspendTag',
      JSON.stringify(suspendTagQuery),
      { headers: this.headers }
    );
  }

  updateUnSuspendTag(suspendTagQuery: SuspendTagQuery): Observable<boolean> {
    // console.log('updateUnSuspendTag headers: ' + JSON.stringify(this.headers));
    //suspendTagQuery.TagList = "testing-unsuspend";
    return this.http.post<boolean>(
      this.endpointUrl + this.accountEndpoint + '/UpdateUnSuspendTag',
      JSON.stringify(suspendTagQuery),
      { headers: this.headers }
    );
  }

  updateUnSuspendTagList(suspendTagQuery: SuspendTagQuery): Observable<number> {
    //var data = 'testdata'; //JSON.stringify(suspendTagQuery);
    var data = JSON.stringify(suspendTagQuery);
    console.log('updateUnSuspendTagList data:' + data + '; headers: ' + JSON.stringify(this.headers));
    return this.http.post<number>(
      this.endpointUrl + this.accountEndpoint + '/UpdateUnSuspendTagList',
      JSON.stringify(suspendTagQuery),
      { headers: this.headers }
    );
  }

  removeTag(suspendTagQuery: SuspendTagQuery): Observable<boolean> {
    return this.http.post<boolean>(
      this.endpointUrl + this.accountEndpoint + '/RemoveTag',
      JSON.stringify(suspendTagQuery),
      { headers: this.headers }
    );
  }

  logOnlinePaymentTransaction(
    transactionResponse: TransactionResponse
  ): Observable<string> {
    return this.reCaptchaService.refresh('LogOnlinePaymentTransaction').pipe(
      switchMap(() => {
        return this.http.post<string>(
          this.endpointUrl +
            this.accountEndpoint +
            '/LogOnlinePaymentTransaction',
          JSON.stringify(transactionResponse),
          { headers: this.headers }
        );
      })
    );
  }

  getInvoiceNumber(AccountId: number): Observable<Invoice> {
    const params = new HttpParams().set('accountId', AccountId.toString());
    return this.http.get<Invoice>(
      this.endpointUrl + this.accountEndpoint + '/GetInvoiceNumber',
      { headers: this.headers, params }
    );
  }

  @Cacheable({
    maxAge: 3000,
    slidingExpiration: true,
    shouldCacheDecider: (response) => response.length > 0,
  })
  getAccountRecentActivities(
    activiyQuery: ActivityQuery
  ): Observable<TransactionRecord[]> {
    return this.http.post<TransactionRecord[]>(
      this.endpointUrl + this.accountEndpoint + '/GetAccountRecentActivities',
      JSON.stringify(activiyQuery),
      { headers: this.headers }
    );
  }

  // with caching
  getTransponderVehicleInfo(accountId: number, forceRefresh: boolean = false) {
    // Data available
    if (this.accountTranspondersCache[accountId] && !forceRefresh) {
      return of(this.accountTranspondersCache[accountId]);
    } else if (this.accountTranspondersObservableCache[accountId]) {
      return this.accountTranspondersObservableCache[accountId];
    } else {
      this.accountTranspondersObservableCache[accountId] =
        this.fetchTransponderVehicleInfo(accountId);
    }
    return this.accountTranspondersObservableCache[accountId];
  }

  fetchTransponderVehicleInfo(accountId: number) {
    const params = new HttpParams().set('accountId', accountId.toString());
    return this.http
      .get<Transponder[]>(
        this.endpointUrl +
          this.accountEndpoint +
          '/GetTransponderVehicleInfo/' +
          accountId.toString(),
        { headers: this.headers }
      )
      .pipe(
        map((resp) => {
          this.accountTranspondersObservableCache[accountId] = null;
          this.accountTranspondersCache[accountId] = resp;
          return this.accountTranspondersCache[accountId];
        }),
        share()
      );
  }

  getTransponderVehicles(accountId: number) {
    const params = new HttpParams().set('accountId', accountId.toString());
    return this.http.get<Transponder[]>(
      this.endpointUrl +
        this.accountEndpoint +
        '/GetTransponderVehicleInfo/' +
        accountId.toString(),
      { headers: this.headers }
    );
  }

  getRequestedVehicleInfo(accountId: number): Observable<Transponder[]> {
    //if (!this.cachedRequestedVehicleInfo) {
      const params = new HttpParams().set('accountId', accountId.toString());
      //this.cachedRequestedVehicleInfo = this.http
      return this.http
        .get<Transponder[]>(
          this.endpointUrl + this.accountEndpoint + '/GetRequestedVehicleInfo',
          { headers: this.headers, params }
        )
        .pipe(
          map((resp) => {
            return resp;
          }),
          publishReplay(1),
          refCount()
        );
    //}

    //return this.cachedRequestedVehicleInfo;
  }

  getAccount(
    accountId: number,
    forceRefresh: boolean = false
  ): Observable<Account> {
    const accountObservable: ReplaySubject<Account> =
      new ReplaySubject<Account>();

    if (
      !forceRefresh &&
      this.currentAccount != null &&
      this.currentAccount.AccountID > 0
    ) {
      accountObservable.next(this.currentAccount);
    } else {
      this.getRefreshAccount(accountId).subscribe((x) => {
        x.LoadTime = this.loadTime;
        this.setActiveAccount(x);
        accountObservable.next(x);
        accountObservable.complete();
      });
    }

    return accountObservable;
  }

  refreshActiveAccount() {
    const curUser = this.getCurrentUser();
    const curAccountId = this.getLocalSelectedAccountID();

    this.getUserAccounts().subscribe((resp) => {
      curUser.Accounts = resp;
      this.localstorageService.setItem(CURRENT_USER, JSON.stringify(curUser));
      this.loadActiveAccount(curAccountId).subscribe();
    });
  }

  getUserAccounts() {
    return this.http
      .get<AccountInfo[]>(
        this.endpointUrl + 'api/Account/GetUserAccountsInfo',
        { headers: this.headers }
      )
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  getRefreshAccount(AccountId: number): Observable<Account> {
    return this.http
      .get<Account>(
        this.endpointUrl + this.accountEndpoint + '/GetAccount/' + AccountId,
        {
          headers: this.headers,
        }
      )
      .pipe(
        map((resp) => {
          const rAcc: Account = resp;
          rAcc.LoadTime = new Date();
          this.currentAccount = rAcc;
          return rAcc;
        })
      );
  }

  requestTransponder(transponderRequest: TransponderRequest) {
    return this.http
      .post(
        this.endpointUrl + this.accountEndpoint + '/TransponderRequest',
        transponderRequest,
        { headers: this.headers }
      )
      .pipe(
        map((resp: boolean) => {
          return resp;
        })
      );
  }

  updateTransponder(transponderRequest: TransponderRequest) {
    return this.http
      .post(
        this.endpointUrl +
          this.accountEndpoint +
          '/UpdateTransponderVehicleInfo',
        transponderRequest,
        { headers: this.headers }
      )
      .pipe(
        map((resp: boolean) => {
          return resp;
        })
      );
  }

  getCurrentUser(): User {
    try {
      return JSON.parse(this.localstorageService.getItem(CURRENT_USER));
    } catch (e) {
      return null;
    }
  }

  updateEInvoiceStatus(updateQuery: EInvoiceStatusUpdateQuery) {
    return this.http
      .post(
        this.endpointUrl + this.accountEndpoint + '/UpdateEInvoiceStatus',
        updateQuery,
        { headers: this.headers }
      )
      .pipe(
        map((resp: Response) => {
          return resp;
        })
      );
  }

  validateQRCode(qrCode: string): Observable<string> {
    return this.http.post<string>(
      this.endpointUrl + this.accountEndpoint + '/ValidateQRCode',
      JSON.stringify(qrCode),
      { headers: this.headers }
    );
  }

  createQrTransponder(transponderRequest: TransponderRequest) {
    return this.http.post<boolean>(
      this.endpointUrl + this.accountEndpoint + '/CreateQrTransponder',
      transponderRequest,
      { headers: this.headers }
    );
  }

  downloadTagList(accountId: number): Observable<any> {
    const params = new HttpParams().set('accountId', accountId.toString());
    return this.http.get(
      this.endpointUrl + this.accountEndpoint + '/DownloadTagList',
      { params, responseType: 'arraybuffer' }
    );
  }

  getTagList(accountId: number): Observable<TransponderExport[]> {
    const params = new HttpParams().set('accountId', accountId.toString());
    return this.http.get<TransponderExport[]>(
      this.endpointUrl + this.accountEndpoint + '/TagList',
      { params, headers: this.headers }
    );
  }

  updateLanguage(languageId: number) {
    var languageCode = 'en';

    if (languageId == 1) {
      languageCode = 'en';
    }

    if (languageId == 2) {
      languageCode = 'fr';
    }

    this.configService.SetClientLanguage(languageCode);
    this.translate.setDefaultLang(languageCode);
    this.translate.use(languageCode);
  }

  confirmTerms(accountId: string): Observable<string> {
    return this.http.post<string>(
      this.endpointUrl + this.accountEndpoint + '/confirmTerms',
      JSON.stringify(accountId),
      { headers: this.headers }
    );
  }

  GetPadAgreement(data): Observable<any> {
    let params = new HttpParams()
    params = params.set('accountId', data.accountId);
    //params = params.set('accountNumber', data.accountNumber);
    params = params.set('name', data.name);
    params = params.set('padDayOfMonth', data.padDayOfMonth);

    return this.http.get(
      this.endpointUrl + this.accountEndpoint + '/GetPadAgreement',
      { params, responseType: 'arraybuffer' }
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
