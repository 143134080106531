import { AlertController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class AlertMobileService {
  constructor(
    private alertCtrl: AlertController,
    public translationService: TranslateService
  ) {}

  async presentConfirm(msg) {
    const alert = await this.alertCtrl.create({
      message: this.translationService.instant(msg),
      buttons: [
        {
          text: this.translationService.instant('CLOSE'),
          handler: () => {},
        },
      ],
    });

    alert.present();
  }

  async presentAlert(header: string, subHeader: string, message: string) {
    const alert = await this.alertCtrl.create({
      header,
      subHeader,
      message,
      buttons: ['OK'],
    });

    await alert.present();
  }
}
