import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Preferences } from '@capacitor/preferences';
import {
  AuthServiceProvider,
  LocalstorageService,
  REFRESH_TOKEN,
} from '@ibitoll/toll-core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LandingGuard implements CanActivate {
  constructor(
    private router: Router,
    private authService: AuthServiceProvider
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    const subject = new Subject<boolean>();
    if (this.authService.loggedIn()) {
      this.router.navigate(['/home/home'], { replaceUrl: true });
      return false;
    } else {
      return new Promise((resolve, reject) => {
        Preferences.get({ key: 'mode' }).then((data: any) => {
          if (!data.value) {
            resolve(true);
          }

          if (data.value && data.value == 'AM') {
            this.router.navigate(['/login'], { replaceUrl: true });
            resolve(false);
          }

          if (data.value && data.value == 'BI') {
            this.router.navigate(['/bridge/volume'], { replaceUrl: true });
            resolve(false);
          }
        });
      });
    }

    return subject.asObservable();
  }
}
