import {
  LogService,
} from '@ibitoll/toll-core';
import { Injectable } from '@angular/core';

import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AlertMobileService } from './alert-mobile.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class PushNotificationWebTestService {

    currentMessage = new BehaviorSubject<any> (null);
    
  constructor(
    public logService: LogService,
    private http: HttpClient,
    private angularFireMessaging: AngularFireMessaging,
    private alertMobileService: AlertMobileService
  ) { }
    
    requestPermission(){
        this.angularFireMessaging.requestToken.subscribe((token) =>  {
            this.postData(token, "bridge-closures").subscribe(
              (response) => {
                console.log("subscribing to bridge-closures notification: successful");
              },
              (error) => {
                console.error("subscribing to bridge-closures notification: ", error);
              }
            );

            this.postData(token, "bridge-alerts").subscribe(
              (response) => {
                console.log("subscribing to bridge-alerts notification: successful");
              },
              (error) => {
                console.error("subscribing to bridge-alerts notification: ", error);
              }
            );

        } )
    }

    receiveNotification(){
        this.angularFireMessaging.messages.subscribe((payload) => {
            console.log("new message: ", payload)
            this.alertMobileService.presentAlert(payload.notification?.title, null, payload.notification?.body)
            this.currentMessage.next(payload)
        })
    }

    postData(token: string, topic: string): Observable<any> {
      const headers = new HttpHeaders({
        'Content-Type': 'application/json',
      });
  
      const params = {
        token: token,
        topic: topic,
        apikey: environment.config.WebNotificationSubscriptionAPIKey
      };

      const url = environment.config.WebNotificationSubscriptionURL
      return this.http.post<any>(url, null, { headers: headers, params: params  });
    }
}
