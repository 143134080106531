import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AlertMobileService, AlertService, ConfigurationService, ValidateAccountQuery, WebAccessQuery, WebAccessService } from '@ibitoll/toll-core';
import { LoadingController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { Keyboard } from '@capacitor/keyboard';

@Component({
  selector: 'mdot-webaccess',
  templateUrl: './webaccess.component.html',
  styleUrls: ['./webaccess.component.scss']
})
export class WebaccessComponent implements OnInit {


  webRequestOptions: any[];
  public selectedRequestOption: string;
  processing: boolean;
  taxFiscalId: string;
  emailSent: boolean;
  accountNumber: string;
  userAccountEmail: string;


  public webRequestValue: string;

  public customPopoverOptions: any = {
    cssClass: 'popover-wide',
  };

  public webRequestForm: FormGroup;
  public webRequestPostForm: FormGroup;
  emailRegex = '^[a-zA-Z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';

  loading: any;

  constructor(
    public translateService: TranslateService,
    public navCtrl: NavController,
    public loadingController: LoadingController,
    public formBuilder: FormBuilder,
    private alertService: AlertMobileService,
    private webAccessService: WebAccessService,
    public configService: ConfigurationService
  ) {
    this.webRequestOptions = this.configService.config.webRequestOptions;
    this.selectedRequestOption = this.webRequestOptions[0].Value;
  }

  ngOnInit(): void {

    this.webRequestForm = this.formBuilder.group({
      requestValueType: ['', Validators.required],
      requestValue: ['', Validators.required],
    });

    this.webRequestPostForm = this.formBuilder.group({
      accountNumber: ['', Validators.required],
      accountEmail: ['', [Validators.required, Validators.pattern(this.emailRegex)]],
    });
  }

  validateTollAccount() {
    this.processing = true;
    const validateAccountQuery: ValidateAccountQuery = {
      QueryValue: this.webRequestForm.controls.requestValue.value,
      QueryType: this.webRequestForm.controls.requestValueType.value,
      TaxFiscalId: this.taxFiscalId,
    };
    this.loadingLogin().then(() => {
      this.webAccessService.validateTollAccount(validateAccountQuery).subscribe(
        (resp) => {
          this.dismissLoading().then(() => {
            this.processing = false;
            this.accountNumber = resp;
            this.webRequestPostForm.controls.accountNumber.setValue(this.accountNumber);
          });
        },
        (error) => {
          this.dismissLoading().then(() => {
            this.processing = false;
          });
        }
      );
    })

  }

  sendActivationEmail() {
    this.processing = true;

    const webAccessQuery = new WebAccessQuery();
    webAccessQuery.UserLogonId = this.accountNumber;
    webAccessQuery.AccountId = this.accountNumber;
    webAccessQuery.UserEmail = this.webRequestPostForm.controls.accountEmail.value;
    webAccessQuery.AccountEmail = this.webRequestPostForm.controls.accountEmail.value;
    this.loadingLogin().then(() => {
      this.webAccessService.sendActivationEmail(webAccessQuery).subscribe(
        (resp) => {
          this.dismissLoading().then(() => {
            if (resp) {
              this.emailSent = true;
              this.processing = false;
            } else {
              this.alertService.errorTranslate(
                'Email Could not be sent. Please try again',
                false
              );
              this.processing = false;
            }
          });
        },
        (error) => {
          this.dismissLoading().then(() => {
            this.processing = false;
          });
        }
      );
    });

  }

  navigateTo(path: string) {
    this.navCtrl.navigateBack([path], { replaceUrl: true });
  }

  async loadingLogin() {
    this.loading = await this.loadingController.create({
      message: this.translateService.instant('PROCESSING_TXT'),
      translucent: true,
    });
    return await this.loading.present();
  }

  async dismissLoading() {
    return await this.loading.dismiss();
  }

  onEnterPress() {
    Keyboard.hide();
  }

}
