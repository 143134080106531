import { environment } from 'src/environments/environment';
import { NativePageTransitions } from '@ionic-native/native-page-transitions/ngx';
import {
  AuthServiceProvider,
  ConfigurationService,
  ErrorhandlerService,
  ErrorService,
  LocalstorageService,
  MobileLocalstorageService,
  TokenInterceptor,
  TollCoreModule,
} from '@ibitoll/toll-core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  APP_INITIALIZER,
  CUSTOM_ELEMENTS_SCHEMA,
  DEFAULT_CURRENCY_CODE,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { pageTransition } from './page-transition';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxCaptchaModule } from 'ngx-captcha';
import { NgxMaskModule } from 'ngx-mask';
import { IonicStorageModule } from '@ionic/storage-angular';
import { TranslateModule } from '@ngx-translate/core';
import { ScriptLoaderModule } from 'ngx-script-loader';
import { PushNotificationService } from './services/push-notification.service';
import { WebaccessComponent } from './pages/webaccess/webaccess.component';
import { FileOpener } from '@ionic-native/file-opener/ngx';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { PushNotificationWebTestService } from './services/push-notification-web-test.service';
import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions/ngx';


export function initializeData(
  localstorageService: MobileLocalstorageService,
  authServiceProvider: AuthServiceProvider,
  configurationService: ConfigurationService
) {
  return () =>
    localstorageService
      .load()
      .then(() =>
        configurationService
          .LoadLocalConfiguration(environment.config)
          .then(() => authServiceProvider.initializeAccount)
      );
}

@NgModule({
  declarations: [AppComponent, WebaccessComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    IonicModule.forRoot({ navAnimation: pageTransition }),
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    NgxMaskModule.forRoot(),
    TollCoreModule.forRoot(),
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    TranslateModule.forRoot(),
    ScriptLoaderModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.config.firebaseConfig),
    AngularFireMessagingModule,
    AngularFireAuthModule
  ],
  providers: [
    NativePageTransitions,
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide: LocalstorageService,
      useClass: MobileLocalstorageService,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeData,
      deps: [
        MobileLocalstorageService,
        AuthServiceProvider,
        ConfigurationService,
      ],
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorService,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: ErrorhandlerService,
    },
    {
      provide: DEFAULT_CURRENCY_CODE,
      useValue: 'USD',
    },
    PushNotificationService,
    FileOpener,
    PushNotificationWebTestService,
    AndroidPermissions
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {}
