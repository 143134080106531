import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthServiceProvider } from './auth.service';
import { ReCaptchaService } from './re-captcha.service';
import { LocalstorageService } from './localstorage.service';
import { REFRESH_TOKEN } from '../models/keyConstand';

@Injectable({
  providedIn: 'root',
})
export class TokenInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );
  auth: AuthServiceProvider;
  gToken: ReCaptchaService;

  constructor(
    private injector: Injector,
    private localstorageService: LocalstorageService
  ) { }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    this.gToken = this.injector.get(ReCaptchaService);
    let newRequest: any;

    // check if request is for refreshtoken, login or logout
    if (
      request.url.includes('refreshtoken') ||
      request.url.includes('login') ||
      request.url.includes('assets') ||
      request.url.includes('api/WebAccess/Logout')
    ) {
      newRequest = request;
    } else if (request.url.includes('Log/LogMessage')) {
      newRequest = this.addGid(request);
    } else {
      // if not clone it and add the access token
      this.auth = this.injector.get(AuthServiceProvider);
      newRequest = this.addToken(request, this.auth.getLoginToken());
    }

    // catch error on response
    return <any>next.handle(newRequest).pipe(
      catchError((error) => {
        if (
          request.url.includes('refreshtoken') ||
          request.url.includes('login')
        ) {
          // if it was refreshtoken request and failed logout the user
          if (
            request.url.includes('refreshtoken') ||
            request.url.includes('Log/LogMessage')
          ) {
            this.auth = this.injector.get(AuthServiceProvider);
            this.auth.logout();
          }
          // throw an error
          return throwError(error);
        } else {
          if (error instanceof HttpErrorResponse) {
            switch ((error as HttpErrorResponse).status) {
              // if it was resource access request and failed due to access token expiry handle it
              case 401:
                return this.handle401Error(request, next);
              // if it is some kind of bad request logout and throw
              // case 400:
              //   return this.logOutThrow(error);

              default:
                // else throw
                return throwError(error);
            }
          } else {
            // in any case throw
            return throwError(error);
          }
        }
      })
    );
  }

  private logOutThrow(error: HttpErrorResponse): Observable<HttpEvent<any>> {
    return <any>this.auth.logout().pipe(
      switchMap(() => {
        return throwError(error.error);
      })
    );
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    // if no refresh token just throw or return
    if (!this.localstorageService.check(REFRESH_TOKEN)) {
      //return throwError(error);
      return next.handle(request);
    }

    // if it is already refreshing don't refresh
    if (!this.isRefreshing) {
      // mark as refresh started
      this.isRefreshing = true;

      // nullify
      this.refreshTokenSubject.next(null);

      // try refresh
      return this.auth.refreshToken().pipe(
        switchMap((token: any) => {
          // if success mark as refresh ended
          this.isRefreshing = false;

          // set the new access?? token
          this.refreshTokenSubject.next(token.access_token);

          // pass to next hanlder
          return next.handle(this.addToken(request, token.access_token));
        }),
        catchError((error: HttpErrorResponse) => {
          this.isRefreshing = false;
          return throwError(error.error);
        })
      );
    } else {
      // TODO: here we need to wait for the refreshing to finish and not return.
      // waitForFinishingRefresh();

      // what does this do? if already running refresh get first non-null token and pass it to next handler?
      return this.refreshTokenSubject.pipe(
        filter((token) => token != null),
        take(1),
        switchMap((jwt: string) => {
          return next.handle(this.addToken(request, jwt));
        })
      );
    }
  }

  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
        gid: this.gToken.gToken,
      },
    });
  }

  private addGid(request: HttpRequest<any>) {
    return request.clone({
      setHeaders: {
        gid: this.gToken.gToken,
      },
    });
  }
}
