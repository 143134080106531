<ion-header>
  <ion-toolbar color="secondary">
    <ion-buttons slot="start">
      <ion-back-button defaultHref="/login"></ion-back-button>
    </ion-buttons>
    <ion-title>{{ 'WEB_ACCESS' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content class="login-page" forceOverscroll="true" fullscreen="false" scrollY="true">
  <div class="login-background">
    <div class="login-inputs">
      <ion-grid class="login-grid">
        <ion-row class="ion-justify-content-center">
          <ion-col size="12" class="ion-align-self-center ">
            <img alt="logo" class="logo" src="../../assets/imgs/logo.png" />
          </ion-col>
          <ion-col size="12" class="ion-align-self-center">
            <ion-label class="login-title ion-text-center ion-text-nowrap">
              <strong> {{ 'WEB_ACCESS_TITLE' | translate }}</strong>
            </ion-label>
          </ion-col>
          <ion-col size="12" class="ion-align-self-center" *ngIf="!emailSent">
            <ion-label class="login-subtitle">
              {{ 'WEB_ACCESS_SUBTITLE' | translate }}
            </ion-label>
          </ion-col>
          <ion-col size="12" class="ion-align-self-center" *ngIf="!accountNumber">
            <form [formGroup]="webRequestForm">
              <ion-item lines="none" class="item-trns">
                <ion-select mode="ios"
                  name="requestValueType" formControlName="requestValueType" 
                  placeholder="{{ 'REQUEST_TYPE' | translate }}">
                  <ion-select-option *ngFor="let option of webRequestOptions" [value]="option.Value">
                    {{ option.Name | translate}}
                  </ion-select-option>
                </ion-select>
              </ion-item>
              <ion-item lines="none" class="item-trns">
                <ion-input clearInput="true" clearOnEdit="true" color="primary" type="text" name="requestValue"
                  formControlName="requestValue" placeholder="{{ 'REQUEST_VALUE' | translate }}" required="true" enterkeyhint="enter" (keyup.enter)="onEnterPress()"
                  contentClass="input-height">
                </ion-input>
              </ion-item>
            </form>
          </ion-col>
          <ion-col size="12" class="ion-align-self-center" *ngIf="accountNumber && !emailSent">
            <form [formGroup]="webRequestPostForm">
              <ion-item lines="none" class="input-label">
                <ion-label>Account ID</ion-label>
              </ion-item>
              <ion-item lines="none" class="item-trns">
                <ion-input color="primary" type="text" name="accountNumber" formControlName="accountNumber"
                  placeholder="{{ 'ACCOUNT-ID' | translate }}" required="true" readonly="true"
                  contentClass="input-height">
                </ion-input>
              </ion-item>
              <ion-item lines="none" class="input-label">
                <ion-label>{{ 'EMAIL' | translate }}</ion-label>
              </ion-item>
              <ion-item lines="none" class="item-trns">
                <ion-input clearInput="true" clearOnEdit="true" color="primary" type="text" autocomplete="email"
                  inputmode="email" name="accountEmail" formControlName="accountEmail"
                  required="true" contentClass="input-height" enterkeyhint="enter" (keyup.enter)="onEnterPress()">
                </ion-input>
              </ion-item>
              <div
                *ngIf="(webRequestPostForm.get('accountEmail').invalid && webRequestPostForm.get('accountEmail').touched) || webRequestPostForm.get('accountEmail').dirty">
                <small class="text-danger" *ngIf="webRequestPostForm.get('accountEmail').errors?.required">Email address
                  is
                  required</small>
                <small class="text-danger" *ngIf="webRequestPostForm.get('accountEmail').errors?.email">Email address is
                  invalid</small>
              </div>
            </form>
          </ion-col>
          <ion-col size="12" class="ion-align-self-center" *ngIf="emailSent">
            <ion-label>
              Please check your inbox and spam folder and click on the link to
              confirm the email address associated with your account. If you did not
              receive the email after 5 minutes, please submit your request again.
            </ion-label>
          </ion-col>
        </ion-row>
        <ion-row class="ion-align-items-center login-btn-row">
          <ion-col size="12" class="ion-no-padding">
            <ion-button expand="block" class="login-btn" [disabled]="!webRequestForm.valid" *ngIf="!accountNumber"
              (click)="validateTollAccount()">
              {{ 'SEND' | translate }}
            </ion-button>

            <ion-button expand="block" class="login-btn" [disabled]="!webRequestPostForm.valid" *ngIf="accountNumber && !emailSent"
              (click)="sendActivationEmail()">
              {{ 'SEND' | translate }}
            </ion-button>
          </ion-col>
        </ion-row>
        <ion-row class="login-footer ion-align-items-center" (click)="navigateTo('/login')">
          <ion-col style="font-size: 15px" class="ion-no-padding">
            <div class="login-bottom-center">
              {{ 'BACK' | translate }}
            </div>
          </ion-col>
        </ion-row>
      </ion-grid>
    </div>
  </div>
</ion-content>
