import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { Closure, ClosureService } from '@ibitoll/toll-core';
import { find } from 'lodash';
import { ReplaySubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ClosureDataService {
  public clrbList: Closure[] = [];
  clouserCount = new ReplaySubject<number>(0);

  constructor(private closureService: ClosureService) {}

  loadData() {
    this.closureService.getClosure().subscribe(async (clr) => {
      this.clrbList = clr.sort((a, b) => {
        return b.Id - a.Id;
      });

    });
  }

  getClouser() {
    return this.clouserCount.asObservable();
  }

  public getUnreadClouser() {
    this.closureService.getClosure().subscribe(
      async (clr) => {
        this.clrbList = clr.filter(x => x.ClosureEndTime == undefined || new Date(x.ClosureEndTime) > new Date());
        var count = 0;
        const { value } = await Preferences.get({ key: 'viewed' });
        if (value) {
          var viewedClosure = JSON.parse(value) as Array<string>;

          var newClrbList: Closure[] = [];
          this.clrbList?.forEach((element) => {
            if (!viewedClosure.includes(element.Id.toString())) {
              newClrbList.push(element);
            }
          });
          count = newClrbList.length;
          this.clouserCount.next(count);
        }
      },
      (error) => {
        this.clouserCount.error('Unable to verify');
      }
    );
    return this.clouserCount.asObservable();
  }
}
