import { NgModule } from '@angular/core';
import { PreloadAllModules, Routes, RouterModule } from '@angular/router';
import { AuthGuard, InActiveAccountGuard, LoginGuard } from '@ibitoll/toll-core';
import { HhbLoginGuard } from './gaurds/hhb-login.guard';
import { LandingGuard } from './gaurds/landing.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'bridge/volume',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/main/main.module').then((m) => m.MainPageModule),
    canActivate: [],
  },
  {
    path: 'landing',
    loadChildren: () =>
      import('./pages/landing-page/landing-page.module').then(
        (m) => m.LandingPageModule
      ),
    canActivate: [LandingGuard],
  },
  {
    path: 'bridge',
    loadChildren: () =>
      import('./pages/bridge-management/bridge-management.module').then(
        (m) => m.BridgeManagementModule
      ),
    canActivate: [],
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginPageModule),
    canActivate: [HhbLoginGuard],
  },
  {
    path: 'payment',
    loadChildren: () =>
      import('./pages/payment/payment.module').then((m) => m.PaymentPageModule),
    canActivate: [AuthGuard, InActiveAccountGuard],
  },
  {
    path: 'transponder',
    loadChildren: () =>
      import('./pages/transponder/transponder.module').then(
        (m) => m.TransponderPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'account',
    loadChildren: () =>
      import('./pages/account/account.module').then((m) => m.AccountPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'account-details',
    loadChildren: () =>
      import('./pages/account-details/account-details.module').then(
        (m) => m.AccountDetailsPageModule
      ),
  },
  {
    path: 'statement',
    loadChildren: () =>
      import('./pages/statement/statement.module').then(
        (m) => m.StatementPageModule
      ),
  },
  {
    path: 'change-password',
    loadChildren: () =>
      import('./pages/change-password/change-password.module').then(
        (m) => m.ChangePasswordPageModule
      ),
  },
  {
    path: 'forgot-password',
    loadChildren: () =>
      import('./pages/forgot-password/forgot-password.module').then(
        (m) => m.ForgotPasswordModule
      ),
  },
  {
    path: 'forgot-username',
    loadChildren: () =>
      import('./pages/forgot-username/forgot-username.module').then(
        (m) => m.ForgotUsernameModule
      ),
  },
  {
    path: 'forgot-username',
    loadChildren: () =>
      import('./pages/forgot-username/forgot-username.module').then(
        (m) => m.ForgotUsernameModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: 'webaccess',
    loadChildren: () =>
      import('./pages/webaccess/webaccess.module').then(
        (m) => m.WebaccessModule
      ),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
