import { TranslateService } from '@ngx-translate/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable, OnDestroy } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';
import { map, publishReplay, refCount, switchMap } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { AccountCreationFee } from '../models/accountCreationFee';
import { Config, EnvironmentInterface } from '../models/config';
import { CountryInfo } from '../models/countryInfo';
import { AccountProfileConfig, IdNameConfig } from '../models/idNameConfig';
import { Invoice } from '../models/invoice';
import { EURO_CURRENCY_CODE, LANGUAGE_KEY } from '../models/keyConstand';
import { ProvinceInfo } from '../models/provinceInfo';
import { TollCategoryInfo } from '../models/tollCategoryInfo';
import { CenposConfig } from './../models/cenposConfig';
import { LocalstorageService } from './localstorage.service';
import { ProxyService } from './proxy.service';
import { ThemeService } from './theme.service';
import { TaxAuthorityInfo } from '../models/taxAuthorityInfo';
import { PlazaFaresInfo } from '../models/plazaFaresInfo';
import { Cacheable } from 'ts-cacheable';
import { NodeInfo } from '../models/nodeInfo';
import { ReCaptchaService } from './re-captcha.service';
import { TransponderType } from '../models/transponderType';
import { TransponderFee } from '../models/transponderFee';
import { Language } from '../models/language';
import { CarMake, CarModel } from '../models/carMake';
import { Console, error } from 'console';
import { Platform } from '@ionic/angular';
import { Logger } from 'html2canvas/dist/types/core/logger';

@Injectable({
  providedIn: 'root',
})
export class ConfigurationService implements OnDestroy {

  private endpointUrl = '';

  private configurationEndpoint = 'api/Configuration';

  private cachedTollCategories: Observable<TollCategoryInfo[]>;

  private cachedAccountProfileConfig: Observable<AccountProfileConfig[]>;

  private cachedTaxAuthorityInfo: Observable<TaxAuthorityInfo[]>;

  private cachedProvinces: Observable<ProvinceInfo[]>;

  private cachedTransponderTypes: Observable<IdNameConfig[]>;

  private cachedCenposMerchantId: Observable<string>;

  public config: Config;
  public environment: EnvironmentInterface;

  private cachedRunMode = '-'; // 'D' - Dev; 'P' - Prod

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  private subs = new SubSink();

  cachedCountries: Observable<CountryInfo[]>;

  cachedNodes: Observable<NodeInfo[]>;

  constructor(
    private http: HttpClient,
    private proxy: ProxyService,
    private localStorage: LocalstorageService,
    private themeService: ThemeService,
    private translateService: TranslateService,
    private reCaptchaService: ReCaptchaService,
    private platform: Platform,
  ) {
    this.subs.add(
      this.proxy.getEndpoint().subscribe((url) => {
        this.endpointUrl = url;
      })
    );
  }

  resetAllConfigurationServiceCache() {
    this.resetCachedTollCategories();
    this.resetCachedAccountProfileConfig();
    this.resetCachedProvinces();
    this.resetCachedTransponderTypes();
    this.resetCachedCenposMerchantId();
  }

  resetCachedTollCategories() {
    this.cachedTollCategories = null;
  }

  resetCachedAccountProfileConfig() {
    this.cachedAccountProfileConfig = null;
  }

  resetCachedProvinces() {
    this.cachedProvinces = null;
  }

  resetCachedTransponderTypes() {
    this.cachedTransponderTypes = null;
  }

  resetCachedCenposMerchantId() {
    this.cachedCenposMerchantId = null;
  }

  public GetLocalClientConfiguration() {
    return new Promise((resolve) => {
      this.http.get<Config>('assets/clientConfig.json').subscribe(
        (x) => {
          this.setConfig(x);
          resolve(true);
        },
        (error) => {
          console.log(error);
          resolve(true);
        }
      );
    });
  }

  loadJsonConfig(): Promise<void> {
    return this.loadRunMode().then(() => {
      const configUrl = this.cachedRunMode === 'P' 
        ? 'assets/environment.prod.json' 
        : 'assets/environment.json';
      return lastValueFrom(this.http.get<EnvironmentInterface>(configUrl)).then(envJson => {
        //this.config = config;
        this.environment = envJson;
        this.setConfig(envJson.config);
        console.log('loadJsonConfig: URL=' + envJson.config.ChaseHPFLink);
      });
    });
  }  

  public LoadLocalConfiguration(config: any) {
    console.log('LoadLocalConfiguration: URL=' + config.ChaseHPFLink);
    return new Promise((resolve) => {
      this.setConfig(config);
      this.loadRunMode().then(() => {
        resolve(true);
      })
    });
  }

  private loadRunMode(): Promise<void> {
    return this.getConfigParameter('AppRunMode').toPromise().then(
      runMode => {
        console.log('loadRunMode->' + runMode);
        if (runMode !== 'P') {
          runMode = 'D';
        }
        console.log('Set loadRunMode->' + runMode);
        this.cachedRunMode = runMode;
      },
      error => {
        console.error('Failed to load run mode:', error);
        this.cachedRunMode = 'D'; // Default value
      }
    );
  }
  
  getEnv(): EnvironmentInterface {
    return this.environment;
  }

  public setConfig(x: Config) {
    this.endpointUrl = x.serverUrl;
    this.config = x;
    this.themeService.setActiveTheme(this.config);
    this.proxy.setEndpoint(x.serverUrl);
    this.loadLanguage();
  }

  loadLanguage() {
    this.translateService.setDefaultLang(this.config.DefaultLanguage);
    this.translateService.use(this.config.DefaultLanguage);
  }

  public GetClientLanguage(): string {
    if (this.localStorage.check(LANGUAGE_KEY)) {
      return this.localStorage.getItem(LANGUAGE_KEY);
    } else {
      if (this.config) {
        if (this.config) {
          this.localStorage.setItem(LANGUAGE_KEY, this.config.DefaultLanguage);
        }

        return this.config.DefaultLanguage;
      } else {
        return 'en';
      }
    }
  }

  public GetCurrencyCode(): any {
    if (this.config) {
      if (this.config.DefaultCurrencyCode === EURO_CURRENCY_CODE) {
        return '€ ';
      } else {
        return '$';
      }
    } else {
      return '$';
    }
  }

  public SetClientLanguage(language: string): void {
    if (language) {
      this.localStorage.setItem(LANGUAGE_KEY, language);
      this.config.DefaultLanguage = language;
    }
  }

  public getBrowserLang(): string {
    return this.translateService.getBrowserLang();
  }

  getCenposMerchantId(): Observable<string> {
    if (!this.cachedCenposMerchantId) {
      return this.http
        .get<string>(
          this.endpointUrl +
            this.configurationEndpoint +
            '/GetCenposMerchantId',
          { headers: this.headers }
        )
        .pipe(
          map((resp) => {
            return resp;
          })
        );
    }

    return this.cachedCenposMerchantId;
  }

  isIbiDev() {
    return this.cachedRunMode === 'D';
  }
  isIbiProd() {
    return this.cachedRunMode === 'P';
  }


  @Cacheable({
    maxAge: 86400000, // 24 h - does not work, must be get request to be cached
    slidingExpiration: true,
    shouldCacheDecider: (response) => response.length > 0,
  })
  getConfigParameter(parameterName: string): Observable<string> {
    return this.http
      .post<string>(
        this.endpointUrl + this.configurationEndpoint + '/GetConfigParameter',
        JSON.stringify(parameterName),
        { headers: this.headers }
      )
      .pipe(
        map((resp) => {
          console.log(
            'Parameter(' + parameterName + ') read: ' + JSON.stringify(resp)
          );
          return resp;
        })
      );
  }

  // getSecureToken(): Observable<string> {
  //   const nHeaders = this.headers.append('X-Client-Ref', LogService. .encodeDateToken());
  //   return this.http
  //     .post<string>(
  //       this.endpointUrl + this.configurationEndpoint + '/GetSecureToken',
  //       { headers: this.headers }
  //     )
  //     .pipe(
  //       map((resp) => {
  //         console.log(
  //           'getSecureToken() => ' + JSON.stringify(resp)
  //         );
  //         return resp;
  //       })
  //     );
  // }

  // @Cacheable({
  //   maxAge: 86400000, // 24 h
  //   slidingExpiration: true,
  //   shouldCacheDecider: (response) => response.length > 0,
  // })
  // getDBDatetime(): Observable<string> {
  //     return this.http
  //     .get<string>(
  //       this.endpointUrl + this.configurationEndpoint + '/GetDatabaseDateTime',
  //       { headers: this.headers }
  //     )
  //     .pipe(
  //       map((resp) => {
  //         return resp;
  //       })
  //     );

  // }
  
  // retrieve Recaptcha from WebAPI. 
  // getReCaptchaKey() {
  //   this
  //     .getConfigParameter('GoogleRecaptchaSiteKey')
  //     .subscribe(
  //       (resp) => {
  //         var recaptchaKey = '';
  //         if (resp !== '') {
  //           recaptchaKey = resp;
  //           this.themeService.RefreshRecatchaKey(recaptchaKey);
  //         }
  //         console.log('Loaded: GoogleRecaptchaSiteKey=' + recaptchaKey);
  //       },
  //       (error) => {
  //         console.log(error);
  //       }
  //     );

  //   // this.getDBDatetime().subscribe(
  //   //   (resp) => {
  //   //     console.log('DB time =' + resp);
  //   //   },
  //   //   (error) => {
  //   //     console.log('DB time error' + JSON.stringify(error));
  //   //   }
  //   // )
  // }

  getIbiSystemIntegrityCheck(): Observable<string[]> {
    return this.http
      .post<string[]>(
        this.endpointUrl +
          this.configurationEndpoint +
          '/IbiSystemIntegrityCheck',
        { headers: this.headers }
      )
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  getInitialInvoiceNumber(): Observable<Invoice> {
    return this.reCaptchaService.refresh('getInitialInvoiceNumber').pipe(
      switchMap(() => {
        return this.http.post<Invoice>(
          this.endpointUrl +
            this.configurationEndpoint +
            '/GetInitialInvoiceNumber',
          {}
        );
      })
    );
  }

  @Cacheable({
    maxAge: 2000,
    slidingExpiration: true,
    shouldCacheDecider: (response) => response.length > 0,
  })
  getAccountCreateFeeType(
    accountProfileId: number
  ): Observable<AccountCreationFee> {
    return this.http
      .post<AccountCreationFee>(
        this.endpointUrl +
          this.configurationEndpoint +
          '/GetAccountCreateFeeType',
        JSON.stringify(accountProfileId),
        { headers: this.headers }
      )
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  getWebPayConfig(): Observable<CenposConfig> {
    return this.http
      .get<CenposConfig>(
        this.endpointUrl + this.configurationEndpoint + '/GetWebPayConfig',
        { headers: this.headers }
      )
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  getSimpleWebPayConfig(): Observable<CenposConfig> {
    return this.http
      .get<CenposConfig>(
        this.endpointUrl +
          this.configurationEndpoint +
          '/GetSimpleWebPayConfig',
        { headers: this.headers }
      )
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  @Cacheable({
    maxAge: 120000,
    slidingExpiration: true,
    shouldCacheDecider: (response) => response.length > 0,
  })
  GetTollCategoryInfoByAccountProfileID(
    accountProfileID: string
  ): Observable<TollCategoryInfo[]> {
    const params = new HttpParams().set('accountProfileID', accountProfileID);
    return this.http.get<TollCategoryInfo[]>(
      this.endpointUrl +
        this.configurationEndpoint +
        '/GetTollCategoryInfoByAccountProfileID',
      { headers: this.headers, params: params }
    );
  }

  @Cacheable({
    maxAge: 120000,
    slidingExpiration: true,
    shouldCacheDecider: (response) => response.length > 0,
  })
  getTollCategories(): Observable<TollCategoryInfo[]> {
    if (!this.cachedTollCategories) {
      this.cachedTollCategories = this.http
        .get<TollCategoryInfo[]>(
          this.endpointUrl +
            this.configurationEndpoint +
            '/GetTollCategoryInfoList',
          { headers: this.headers }
        )
        .pipe(
          map((resp) => {
            console.log('getTollCategories->' + JSON.stringify(resp));
            return resp;
          }),
          publishReplay(),
          refCount()
        );
    }

    return this.cachedTollCategories;
  }

  @Cacheable({
    maxAge: 120000,
    slidingExpiration: true,
    shouldCacheDecider: (response) => response.length > 0,
  })
  getTollCategoryInfoByAccountProfile(
    accountId: number
  ): Observable<TollCategoryInfo[]> {
    const params = new HttpParams().set('accountId', accountId.toString());
    return this.http.get<TollCategoryInfo[]>(
      this.endpointUrl +
        this.configurationEndpoint +
        '/GetTollCategoryInfoByAccountProfile',
      { headers: this.headers, params }
    );
  }

  // getDefaultProvinceId(): any {
  //   var defaultProvinceId = 1;
  //   this.getProvinces().subscribe((data) => {

  //     var defaultProvince:ProvinceInfo[] = data.filter((p) => {
  //       return (
  //         p.Name === 'Nova Scotia'
  //       )
  //     });
  //     if (defaultProvince) {
  //       defaultProvinceId = defaultProvince[0].Id;
  //     }
  //     return defaultProvinceId;

  //   });
  // }

  @Cacheable({
    maxAge: 120000,
    slidingExpiration: true,
    shouldCacheDecider: (response) => response.length > 0,
  })
  getProvinces(): Observable<ProvinceInfo[]> {
    return this.http.get<ProvinceInfo[]>(
      this.endpointUrl + this.configurationEndpoint + '/GetProvinceInfoList',
      { headers: this.headers }
    );
  }

  @Cacheable({
    maxAge: 120000,
    slidingExpiration: true,
    shouldCacheDecider: (response) => response.length > 0,
  })
  getCountries(): Observable<CountryInfo[]> {
    if (!this.cachedCountries) {
      this.cachedCountries = this.http
        .get<CountryInfo[]>(
          this.endpointUrl + this.configurationEndpoint + '/GetCountyInfoList',
          { headers: this.headers }
        )
        .pipe(
          map((resp) => {
            return resp;
          }),
          publishReplay(1),
          refCount()
        );
    }
    return this.cachedCountries;
  }

  @Cacheable({
    maxAge: 120000,
    slidingExpiration: true,
    shouldCacheDecider: (response) => response.length > 0,
  })
  getNodes(): Observable<NodeInfo[]> {
    return this.http
      .get<NodeInfo[]>(
        this.endpointUrl + this.configurationEndpoint + '/GetNodeInfoList',
        { headers: this.headers }
      )
      .pipe(
        map((resp) => {
          return resp;
        }),
        publishReplay(1),
        refCount()
      );
  }

  getTransponderTypes(): Observable<IdNameConfig[]> {
    if (!this.cachedTransponderTypes) {
      this.cachedTransponderTypes = this.http
        .get<IdNameConfig[]>(
          this.endpointUrl +
            this.configurationEndpoint +
            '/GetTransponderTypes',
          { headers: this.headers }
        )
        .pipe(
          map((resp) => {
            return resp;
          }),
          publishReplay(1),
          refCount()
        );
    }

    return this.cachedTransponderTypes;
  }

  @Cacheable({
    maxAge: 120000,
    slidingExpiration: true,
    shouldCacheDecider: (response) => response.length > 0,
  })
  getTransponderFees(): Observable<TransponderFee[]> {
    return this.http.get<TransponderFee[]>(
      this.endpointUrl + this.configurationEndpoint + '/GetTransponderFees',
      { headers: this.headers }
    );
  }

  @Cacheable({
    maxAge: 120000,
    slidingExpiration: true,
  })
  getAccountProfiles(
    iscreateMode: boolean = false
  ): Observable<AccountProfileConfig[]> {
    return this.http
      .get<AccountProfileConfig[]>(
        this.endpointUrl + this.configurationEndpoint + '/GetAccountProfiles',
        { headers: this.headers }
      )
      .pipe(
        map((resp) => {
          if (iscreateMode) {
            return resp.filter((resp2) => resp2.Selectable === true);
          }
          return resp;
        })
      );
  }

  getTaxAuthorities(): Observable<TaxAuthorityInfo[]> {
    if (!this.cachedTaxAuthorityInfo) {
      this.cachedTaxAuthorityInfo = this.http
        .get<TaxAuthorityInfo[]>(
          this.endpointUrl + this.configurationEndpoint + '/GetTaxAuthorities',
          { headers: this.headers }
        )
        .pipe(publishReplay(1), refCount());
    }
    return this.cachedTaxAuthorityInfo;
  }

  getCurrentPlazaFares(): Observable<PlazaFaresInfo[]> {
    return this.http.get<PlazaFaresInfo[]>(
      this.endpointUrl + this.configurationEndpoint + '/GetCurrentPlazaFares',
      { headers: this.headers }
    );
  }

  @Cacheable({
    maxAge: 120000,
    slidingExpiration: true,
  })
  getLanguage(): Observable<Language[]> {
    return this.http.get<Language[]>(
      this.endpointUrl + this.configurationEndpoint + '/GetLanguage',
      { headers: this.headers }
    );
  }

  public GetClientConfiguration(): Observable<Config> {
    return this.http.get<Config>(
      this.endpointUrl + 'api/Configuration/GetClientConfig'
    );
  }

  public GetCarMake(): Observable<CarMake[]> {
    return this.http.get<CarMake[]>(
      this.endpointUrl + 'api/Configuration/getCarMake'
    );
  }

  public GetCarModel(): Observable<CarModel[]> {
    return this.http.get<CarModel[]>(
      this.endpointUrl + 'api/Configuration/getCarModel'
    );
  }

  public CheckDbStatus(): Observable<boolean> {
    return this.http.get<boolean>(
      this.endpointUrl + 'api/Configuration/IsOnline'
    );
  }
  public GetSToken() { // should be set on API in config: key=connectionSecurityToken
    return 'L;^tnJ/P4=;C5{e[Hu6jY]dKg.},X:=xFm>!rW;DTGk$N8qw%p';
  }
  
  public GetInAppChaseWrapperUrl() {
    var url = 'https://www.mymacpass.com/payment/chase-wrapper';
    if (this.isIbiDev()) {
      url = 'https://ibihhb.club/payment/chase-wrapper';
    }
    return url;
  }
  public GetChaseHPFLink() {
    var url = 'https://chase.hostedpaymentservice.net/hpf/?uid=';
    if (this.isIbiDev()) {
      url = 'https://chase-var.hostedpaymentservice.net/hpf/?uid=';
    }
    return url;
  }

  public SaveClientConfiguration(configuration: Config): void {
    this.http
      .post(
        this.endpointUrl + 'api/Configuration/SaveClientConfig',
        configuration
      )
      .subscribe(() => {
        window.location.reload();
      });
  }

  getMobileVersionUpdateLink(): Observable<string> {
    const nheaders = new HttpHeaders({
      'Content-Type': 'application/json',
    });
    let configName = ''
    if (this.platform.is('ios')) {
      configName = 'iOSUpdateLink';
    }
    else{
      configName = 'AndroidUpdateLink';
    }
    return this.http.post<string>(
        this.endpointUrl + 'api/Configuration/GetConfigParameter',
        JSON.stringify(configName),
        { headers: nheaders }
      )
  }


  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
