import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, isDevMode, OnDestroy } from '@angular/core';
import { ScriptService } from 'ngx-script-loader';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { Account } from '../models/account';
import { ChaseResponse } from '../models/chaseResponse';
import { CreditCard } from '../models/creditCard';
import { PaymentViolationInfo } from '../models/paymentViolation';
import { PaymentViolationQuery } from '../models/PaymentViolationQuery';
import { PrepChasePaymentQuery } from '../models/prepChasePaymentQuery';
import { TransactionReceipt } from '../models/transactionReceipt';
import { TransactionResponse } from '../models/transactionResponse';
import { PreLoadResponse } from  '../models/preLoadResponse';
import { ProxyService } from './proxy.service';
import { ReCaptchaService } from './re-captcha.service';
import { UtilsService } from './utils.service';
import { NewAccountRequest } from '@ibitoll/toll-core';

@Injectable({
  providedIn: 'root',
})
export class ChasePaymentService implements OnDestroy {
  private endpointUrl: string;

  private configurationEndpoint = 'api/Payment';
  private chaseEndpoint = 'api/Chase';

  private subs = new SubSink();

  private headers = new HttpHeaders({
    'Content-Type': 'application/json',
  });

  constructor(
    private http: HttpClient,
    private proxy: ProxyService,
    private scriptService: ScriptService,
    private reCaptchaService: ReCaptchaService,
    private utilsService: UtilsService
  ) {
    this.subs.add(
      this.proxy.getEndpoint().subscribe((url) => {
        this.endpointUrl = url;
      })
    );

    //akb
    if (this.utilsService.isHHB()) {
      if (isDevMode()) {
        this.scriptService
          .loadScript(
            'https://chase-var.hostedpaymentservice.net/includes/hpfParent.min.js' 
          )
          .subscribe(() => {});
      } else {
        this.scriptService
          .loadScript(
            'https://chase.hostedpaymentservice.net/includes/hpfParent.min.js'
          )
          .subscribe(() => {});
      }
    }
  }

  prepareChasePayment(amount: number): Observable<PrepChasePaymentQuery> {
    return this.http
      .get<PrepChasePaymentQuery>(
        this.endpointUrl +
          this.configurationEndpoint +
          '/prepare/' +
          amount +
          '/',
        { headers: this.headers }
      )
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  prepareChasePaymentViolation(
    query: PaymentViolationQuery
  ): Observable<PrepChasePaymentQuery> {
    return this.http
      .post<PrepChasePaymentQuery>(
        this.endpointUrl + this.configurationEndpoint + '/prepare/pv/',
        JSON.stringify(query),
        { headers: this.headers }
      )
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  getTxnReceipt(orderId: string): Observable<TransactionReceipt> {
    let txnReceiptRoute = '/receipt/';
    if (orderId.includes('_pv_')) {
      txnReceiptRoute = '/receipt/pv/';
    }
    console.log('getTxnReceipt: ' + orderId + '; route: ' + txnReceiptRoute);
    return this.http
      .get<TransactionReceipt>(
        this.endpointUrl +
          this.configurationEndpoint +
          txnReceiptRoute +
          orderId +
          '/',
        { headers: this.headers }
      )
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  GetPaymentViolationInfo(
    pvInfo: PaymentViolationInfo
  ): Observable<PaymentViolationInfo> {
    return this.http
      .post<PaymentViolationInfo>(
        this.endpointUrl + this.configurationEndpoint + '/iou/', //  + code + '/'
        JSON.stringify(pvInfo),
        { headers: this.headers }
      )
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  preLoad(
    amount: number,
    accountId: number,
    preLoadType: number,
    language: number,
    formName: string | undefined = undefined
  ): Observable<PreLoadResponse> {
    return this.reCaptchaService.refresh('preLoad').pipe(
      switchMap(() => {
        return this.http.post<PreLoadResponse>(
          this.endpointUrl + this.chaseEndpoint + `/preLoad`,
          { amount, accountId, preLoadType, language, formName },
          { headers: this.headers }
        );
      })
    );
  }

  addCardPreLoad(
    amount: number,
    accountId: number,
    preLoadType: number,
    language: number,
    formName: string | undefined = undefined,
    testChase: string
  ): Observable<PreLoadResponse> {
    console.log('testChase=' + testChase);
    return this.reCaptchaService.refresh('preLoad').pipe(
      switchMap(() => {
        return this.http.post<PreLoadResponse>(
          this.endpointUrl + this.chaseEndpoint + `/addCardPreLoad`,
          { amount, accountId, preLoadType, language, formName, testChase },
          { headers: this.headers }
        );
      })
    );
  }

  postResponse(
    amount: number,
    accountId: number,
    preLoadType: number,
    language: number,
    uId: string
  ): Observable<TransactionResponse> {
    return this.reCaptchaService.refresh('preLoad').pipe(
      switchMap(() => {
        return this.http.post<TransactionResponse>(
          this.endpointUrl + this.chaseEndpoint + `/postResponse`,
          { amount, accountId, preLoadType, language, uId },
          { headers: this.headers }
        );
      })
    );
  }

  preAuthPreLoad(
    amount: number,
    accountId: number,
    language: number
  ): Observable<string> {
    return this.reCaptchaService.refresh('preAuthPreLoad').pipe(
      switchMap(() => {
        return this.http.post<string>(
          this.endpointUrl + this.chaseEndpoint + `/preAuthPreLoad`,
          { amount, accountId, language },
          { headers: this.headers }
        );
      })
    );
  }

  getSignupPreLoad(account: NewAccountRequest): Observable<PreLoadResponse> {
    return this.reCaptchaService.refresh('signupPreLoad').pipe(
      switchMap(() => {
        return this.http.post<PreLoadResponse>(
          this.endpointUrl + this.chaseEndpoint + `/signupPreLoad`,
          JSON.stringify(account),
          { headers: this.headers }
        );
      })
    );
  }

  preAuthResponse(cardUpdate: CreditCard): Observable<string> {
    return this.reCaptchaService.refresh('preAuthResponse').pipe(
      switchMap(() => {
        return this.http.post<string>(
          this.endpointUrl + this.chaseEndpoint + `/preAuthResponse`,
          JSON.stringify(cardUpdate),
          { headers: this.headers }
        );
      })
    );
  }

  processSavedCardPayment(
    accountID: number,
    amount: number
  ): Observable<TransactionResponse> {
    return this.reCaptchaService.refresh('processSavedCardPayment').pipe(
      switchMap(() => {
        return this.http.post<TransactionResponse>(
          this.endpointUrl + this.chaseEndpoint + `/processSavedCardPayment`,
          { accountID, amount },
          { headers: this.headers }
        );
      })
    );
  }

  updatePaymentDetails(cardUpdate: CreditCard): Observable<boolean> {
    return this.reCaptchaService.refresh('updatePaymentDetails').pipe(
      switchMap(() => {
        return this.http.post<boolean>(
          this.endpointUrl + this.chaseEndpoint + '/updatePaymentDetails',
          JSON.stringify(cardUpdate),
          { headers: this.headers }
        );
      })
    );
  }

  addCard(cardUpdate: CreditCard): Observable<boolean> {
    return this.reCaptchaService.refresh('addCard').pipe(
      switchMap(() => {
        return this.http.post<boolean>(
          this.endpointUrl + this.chaseEndpoint + '/addCard',
          JSON.stringify(cardUpdate),
          { headers: this.headers }
        );
      })
    );
  }

  processOneTimePayment(cardUpdate: CreditCard): Observable<TransactionResponse> {
    return this.reCaptchaService.refresh('oneTimePayment').pipe(
      switchMap(() => {
        return this.http.post<TransactionResponse>(
          this.endpointUrl + this.chaseEndpoint + '/oneTimePayment',
          JSON.stringify(cardUpdate),
          { headers: this.headers }
        );
      })
    );
  }

  ngOnDestroy(): void {
    this.subs.unsubscribe();
  }
}
